import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import ProtectedRoute from './components/ProtectedRoute';
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
//******************** */
const AdmonDashboard = React.lazy(() => import('./views/admon_agencies/Dashboard'))
const SIDEABAR = React.lazy(() => import('./views/sidebar/Dashboard'))
const UserDashboard = React.lazy(() => import('./views/admon_users/Dashboard'))
const CustomScriptsDashboard = React.lazy(() => import('./views/admon_customscripts/Dashboard'))
const MenuDashboard = React.lazy(() => import('./views/admon_menu/Dashboard'))
const PccDashboard = React.lazy(() => import('./views/admon_pcc/Dashboard'))
class App extends Component {
  
  render() {
   return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <ProtectedRoute path="/bostonscripts/adm_dashboard" component={AdmonDashboard} />
            <ProtectedRoute path="/bostonscripts/usr_dashboard" component={ UserDashboard} />
            <ProtectedRoute path="/bostonscripts/pcc_dashboard" component={PccDashboard } />
            <ProtectedRoute path="/bostonscripts/customscripts_dashboard" component={ CustomScriptsDashboard} />
            <ProtectedRoute path="/bostonscripts/admon_menu" component={MenuDashboard } />
           
            <Route exact path="/register" name="Register Page" render={(props) => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route exact path="/bostonscripts/dashboard" name="Home" render={(props) => <DefaultLayout {...props} />} />
            {/* <Route  path="/" name="Home" render={(props) => <DefaultLayout {...props} />} /> */}
            <Route  path="/SIDEABAR" name="SIDEABAR" render={(props) => <SIDEABAR {...props} />} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    )
  }
}

export default App
